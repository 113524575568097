import styled, {css} from 'styled-components';
import { DropdownButton, DropdownItem } from "react-bootstrap";
import { CheckLogo } from '../Content/styles';
import {Tabs} from "react-bootstrap";
const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const Layout = styled.div`
  width: 100%;
  margin-bottom: 60px;
  font-family: ${RegularFont};
  padding: 25px;

  @media (max-width: 500px) {
    padding: 30px 0 0;
  }
  .scrollGrid {
    float: left;
    width: 100%;
  }

  .inactiveUsersGrid{
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }
`;

const PageTitle = styled.div`
  font-size: 30px;
  color: rgb(62,78,104);
  font-weight: 300;:
  display: inline-block;

  @media (max-width: 600px) {
    font-size: 25px;
    white-space: nowrap;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
  margin-bottom: 22px;
  >div:first-child{
    width: 100%;
  }
  >div:last-child{
    width: 100%;
  }

  @media (max-width: 660px) {
    display: block;
    margin-bottom: 0;
  }
`;

const Button = styled.button`
  height: ${({newBgColor,listColor,newWidth,addButton,height}) => height?'auto':newBgColor ? "46px" :  listColor ? "30px" : newWidth ? "30px" : 
    addButton ?  "40px" :"40px" };
  width: ${({newBgColor,listColor,newWidth,addButton}) => newBgColor ? "99px" : listColor ? "110px" : newWidth ? "155px" 
    :  addButton ?  "100%" : "184px"};
  border-radius: 3px;
  background-color:${({bgColor,profileColor,greenProfileColor,yellowProfileColor,whiteColor,emptyBg}) => bgColor ? bgColor : 
    profileColor ? '#FD7175' : greenProfileColor ? '#9FC989': yellowProfileColor ?  '#F6B479': whiteColor ? 'white' :
      emptyBg ? 'none':'#9FC989'};
  border: none;
  position: relative;
  font-weight: 500;
  color: ${({whiteColor,colorButton}) => whiteColor ? '#005C87' : colorButton ? '#005C87'  : '#FFFFFF'};
  font-family: ${({fontFamily}) => fontFamily ? 'Rubik-Medium' : 'Rubik' };
  font-style: normal;
  text-align: center;
  text-transform: capitalize;
  padding: 0;
  margin-top: ${({marginTop,newBgColor, margintop}) => newBgColor ? '8px':margintop ?'10px': marginTop && marginTop};
  margin-left: ${({marginLeft}) => marginLeft && marginLeft };
  opacity: ${({disabled}) => disabled ? '0.5' : '1' };
  cursor: ${({disabled}) => disabled && 'not-allowed !important'};
  font-size:${({addButton, listColor, profileColor, font}) => font?'18px':addButton ?'16px': listColor?'16px':profileColor?'16px' : '16px' };
  line-height:${({addButton}) => addButton ? '2px' : 'none' };
  margin:  ${({addButton, newWidth, topPadding, margintop}) => addButton ? '0px':margintop?'10px 0px 0px 0px ':newWidth?"0px" :topPadding?'0px': '20px 15px' };
  padding: ${({height}) => height ? '6px 5px' : '5px' };

  .glyphicon {
  margin-right: 5px;
  outline: none;
  }
`;

const ButtonNew = styled.button`
width: ${({newBgColor,listColor,newWidth,addButton, newBgColors, newBgColorRight}) => newBgColorRight?'105px':newBgColor ? "80px" : listColor ? "80px" : newWidth ? "80px":newBgColors?'80px' 
    :  addButton ?  "80px" : "184px"};
  border-radius: 3px;
  background-color:${({bgColor,profileColor,greenProfileColor,yellowProfileColor,whiteColor,emptyBg}) => bgColor ? bgColor : 
    profileColor ? '#FD7175' : greenProfileColor ? '#9FC989': yellowProfileColor ?  '#F6B479': whiteColor ? 'transparent' :
      emptyBg ? 'none':'#9FC989'};
  border: ${({whiteColor}) => whiteColor ? '1px solid #005C87' : 'none' };
  font-weight: 500;
  color: ${({whiteColor,colorButton}) => whiteColor ? '#005C87' : colorButton ? '#005C87'  : '#FFFFFF'};
  font-family: ${({fontFamily}) => fontFamily ? fontFamily : 'Rubik' };
  font-style: normal;
  text-align: center;
  text-transform: capitalize;
  padding: 0;
  margin-top: ${({marginTop,newBgColor, newBgColors, newBgColorRight}) => newBgColorRight?'8px':newBgColor  ? '8px' :newBgColors?'8px': marginTop && marginTop};
  margin-right: ${({marginLeft}) => marginLeft && marginLeft };
  margin-right: ${({margin}) => margin ? '10px' : '0' };
  opacity: ${({disabled}) => disabled ? '0.5' : '1' };
  cursor: ${({disabled}) => disabled && 'not-allowed !important'};
  font-size:${({addButton, newBgColor}) => addButton ? '14px': newBgColor? '14px' : '14px' };
  line-height:${({addButton}) => addButton ? '2px' : 'none' };
  padding: 5px 10px;
  padding:${({newBgColor}) => newBgColor ? '11px 0px' : '5px 10px' };
  height:${({newBgColor, newBgColors}) => newBgColor ? '40px' :newBgColors?'40px':"40px" };
  display: ${({display}) => display ? display : '' };
  .glyphicon {
  margin-right: 5px;
  outline: none;
  margin-left:${({margin, marginLeft}) => margin ? margin:marginLeft?marginLeft : '0px' };
  }

`;

const AddLogo = styled(CheckLogo)`
  float: left;
  position: absolute;
  left: 13px;
  border: none;
  background-color: #3498DB;
  box-shadow: none;
  font-size: 18px;
  line-height: 23px;
  width: 23px;
  height: 23px;
  vertical-align: middle;
  top: 8px;
  margin-bottom: 0;

  &:focus, &:active {
    outline: none;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1246px;
  margin: auto;
  display: block;
`;

const ViewContainer = styled.div`
  width: 100%;
  float: left;
  display: block;
  @media (max-width: 1150px) {
    display: block;
  }
`;

const AddButtonContainer = styled.div`
width: 50%;
justify-content: flex-end;
  text-align: right;
  float: left;
    margin-top: 14px;
  display: inline-block;
  @media (max-width: 1150px) {
    display: block;
    margin-right: ${({marginRight}) => marginRight ? marginRight : '40px' };
  }
`;

const MainSection = styled.div`
  width: 68.6%;
  float: left;
  height: 100%;
  padding-right: 25px;

  .pagination {
    margin-top: 60px;
    width: 100%;
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 500px) {
    padding: 0 15px 60px;
  }
`;

const SideSection = styled.div`
height:100%;
  width: 31.4%;
  float: left;
  background-color: ${({ noBackground }) => !noBackground && 'white'};
  box-shadow: ${({ noBackground }) => !noBackground && '0 0 21px rgba(0,0,0,0.08)'};
  border-radius: 4px 4px 0 0;
  margin-top: 9px;
  .events {
    float: left;
    width: 100%;
    margin-top: 20px;
  }
`;

const IconsContainer = styled.div`
    padding: 8px 0;
    border-radius: 3px;
    width: 48px;
  > img {
    cursor: pointer;
    width: 20px;
    &:first-child {
      margin-right: 8px;
    }
  }
`;

const SortSection = styled.div`
  width: 100%;
  position: relative;
  float: left;
  padding: 0px 15px;
  @media(max-width: 600px) {
    display: block;
  }
  .float {
    display: inline-block;
    float: left;
    width: 50%;
    @media (min-width: 600px) and (max-width:750px) {
      display: block;
      float: none;
      width: calc(100%);
    }
    .ipadIssue {
      width: 100%;
      display: block;
      .flex {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        > div:not(:last-child){
          margin-right: 15px;
          display: inline-block;
        }
        > div:nth-child(1) {
          width: 25%;
          height: 40px;
         border: 1px solid #D8D8D8;
         box-sizing: border-box;
           border-radius: 3px;
         
         .btn-group {
           width: 100%;
         }
         @media (max-width: 600px) {
           display: block;
           min-width: 170px;
           width: unset;
         }
       
        }
        > div:nth-child(3) {
          width: 25%;
           height: 40px;
          border: 1px solid #D8D8D8;
          box-sizing: border-box;
            border-radius: 3px;
          
          .btn-group {
            width: 100%;
          }
          @media (max-width: 600px) {
            display: block;
            min-width: 170px;
            width: unset;
          }
        }
        > div:nth-child(2) {
          width: 25%;

    position: relative;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #D8D8D8;
}
          
          .btn-group {
            width: 100%;
          }
          @media (max-width: 600px) {
            display: block;
            min-width: 170px;
            width: unset;
          }
        }
        float: right;
        .sort {
          float: left;
          color: rgb(153,153,153);
        }
        .dropdown-menu {
          min-width: 122px;
          width: 100%;
          max-width: 250px;
          max-height: 200px;
          overflow-y: auto;
          overflow-x: hidden;
          > li > a {
            white-space: normal;

          }
        }
        @media (max-width: 600px) {
          float: left;
          > div {
            margin-top: 9px;
            display: block;
          }
        }
      } 
    }
  }
  > div {
    display: inline-block;
    &:first-child {
      width: 50%;
      position: relative;
      float: left;
      margin-bottom: 15px;
      position: relative;
      > i {
        position: absolute;
        right: 0;
        font-size: 14px;
        top: 14px;
        left: 9px;
        color: #005C87;
        width: 13px;
        float: left;
      }

      @media (max-width: 500px) {
        width: 100%;
      }     
    }

    &:last-child {
      > span {
        font-size: 14px;
        letter-spacing: 0px;
        color: rgb(153,153,153);
        display: inline-block;
        margin-right: 8px;
      }
      > div {
        display: inline-block;
        > div {
          display: inline-block;
        }
      }
    }
  }

  .btn-group.open .dropdown-toggle {
    box-shadow: none;
    background-color: white;
    border: 1px solid #cdcbcb;
  }

  .dropdown-menu {
    min-width: 100%;
    width: auto;
    > ul {
      max-width: 300px;
    }

    @media (max-width: 1460px) and (min-width: 1250px) {
      width: 100px;
      > li {
        > a {
          padding: 3px 7px;
        }
      }
    }

    @media (max-width: 1249px) and (min-width: 1150px) {
      width: 115px;
      > li {
        > a {
          padding: 3px 7px;
        }
      }
    }
  }
`;

const StyledInput = styled.input`
.placeholder{
  color: red;

}
placeholder{
  color: red;
  
}
  padding: 0px 35px;
    font-family: 'Rubik';
    font-style: 'normal';
    font-weight: 'normal';
    font-size: 14px;
    line-height: 24px;
    width: 60%;
    height: 40px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
  border-radius: 3px;
  color: #005C87;
  
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: 'Rubik';
    color: #005C87;
  }
  :-ms-input-placeholder {
     color: red;
  }

  button, input, optgroup, select, textarea{
    color: #005C87;
  }
  
  &:hover, &:active, &:focus {
    outline: none;
  }
`;

const HeaderTitlePeopleHomeV2 = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  color: #005C87;
  font-family: ${BoldFont};
  text-transform: none;
  line-height: 18px;
  text-align: left;
  margin-right: ${({marginRight}) => marginRight ? marginRight : '0' };
  @media (max-width: 500px) {
    margin: 10px 0;
  }
  > span {
    color: #005C87;
    font-size: 20px;
  }
`;


const HeaderImagePeopleHomeV2 = styled.img`
  height: 24px;
  margin-right: 9px;
`;

const HeaderContainerPeopleHomeV2 = styled.div`
  width: 100%;
  padding: ${({ isLongContainer }) => isLongContainer ? '21px 17px 15px' : '17px 17px 11px'};
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: ${({setColor}) => setColor ? setColor : ''};
  border-top-left-radius: ${({setColor}) => setColor ? '5px' : '0'};
  border-top-right-radius: ${({setColor}) => setColor ? '5px' : '0'};
  padding: ${({setColor}) => setColor ? '10px 17px 10px' : '5px 17px 0px 2px'};
  min-height: 56px;
  justify-content: flex-start;
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    @media (max-width: 500px) {
      display: block;
    }
    &:nth-child(2) {
      > span {
        font-size: 16px;
        color: ${({setColor}) => setColor ? '#fff' : 'rgb(153,153,153)'};
        > h5 {
          padding-right: ${({setColor}) => setColor ? '10px' : '0px'};
          margin: 0;
        }
      }
    }
  }

  ${({ textAlign }) => textAlign && css`
    height: 52px;
    > div {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%;
    }
  `}

  @media (max-width: 500px) {
    padding: ${({ isLongContainer }) => isLongContainer ? '21px 10 15px' : '17px 10px 11px'};
  }

  ${({ noPadding }) => noPadding && css`
    padding: 21px 10 15px;

    @media (max-width: 500px) {
      padding: 21px 10 15px;
    }
  `}
  .profile_img {
    width: 45px;
    > img {
      width: 30px;
      height: 22px;
      margin-left: 15px;
    }
  }
`;

const CustomDropDownItem = styled(DropdownItem)`
font-family:'Rubik-Light'
`



const CustomDropDown = styled(DropdownButton)`
  width: 100%;
  display: ${({inputdisplay}) => inputdisplay ? inputdisplay : 'inline-block'};
  color: ${({newWidth}) => newWidth ? 'white' : 'white'};
  background-color: ${({newWidth}) => newWidth ? '#005C87' : '#ffffff'};
  align-items: center;
  padding: 0px 10px;
  border: 1px solid #cdcbcb;
  text-align: left;
  border: none;
  padding: ${({inputpadding,newWidth}) => inputpadding ? inputpadding : newWidth ? '9px 16px' : '9px 29px'};
  border-radius: 3px;
  color: #005C87;
  &:active, &:hover, &:focus, &:active:focus {
    color: #005C87;
  }
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Rubik';
    float: left;
    max-width:118px;
    white-space:nowrap;
    font-size: 14px;
    line-height: 20px;
    color: #005c87;
  }
  > ul {
    max-width: 300px !important;
  }
  font-size: 14px;
  letter-spacing: 0px;
  color: #282C37;
  font-family: ${MediumFont};
  color: #005C87;
  .caret {
    /* margin-right: 8px; */
    float: right;
    /* margin-top: 9px; */
    display: flex;
    align-self: center;
    color: ${({caretColor}) => caretColor ? '#69c2ff' : '#005C87'}
  }
  > li > a {
    text-overflow: ellipses;
    white-space: normal;
    overflow: hidden;
  }
  > span {
    color: #a3a3a3;
    float: right;
    border-top: 6px dashed;
    margin-top: 7px;
  }
  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
     background-color: transparent;
    color: #005C87;
  }

  button{
    background:transparent !important;
    border:none;
    color:#005C87;
  }

  button:hover{
    color:#005C87;
  }

  button:focus{
    background-color: transparent !important;
  }
  button:focus-visible{
    background-color: transparent !important;
  }
  button:active{
    background-color: transparent !important;
  }

  button{
    background:transparent !important;
    border:none;
    color:#005C87;
  }

  button:hover{
    color:#005C87;
  }

  button:focus{
    background-color: transparent !important;
  }
  button:focus-visible{
    background-color: transparent !important;
  }
  button:active{
    background-color: transparent !important;
  }
`;

const CustomDropDownv3 = styled(DropdownButton)`
  width: 100%;
  display: ${({inputdisplay}) => inputdisplay ? inputdisplay : 'inline-block'};
  color: ${({newWidth}) => newWidth ? 'white' : 'white'};
  background-color: ${({newWidth}) => newWidth ? '#005C87' : '#faf9f4'};
  align-items: center;
  padding: 0px 10px;
  border: 1px solid #afcbd3;
  text-align: left;
  border: none;
  padding: ${({inputpadding,newWidth}) => inputpadding ? inputpadding : newWidth ? '9px 16px' : '9px 29px'};
  border-radius: 3px;
  color: #005C87;
  &:active, &:hover, &:focus, &:active:focus {
    color: #005C87;
    background:#faf9f4;
  }
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Rubik';
    float: left;
    max-width:118px;
    white-space:nowrap;
    font-size: 14px;
    /* line-height: 24px; */
    color: #005c87;
  }
  > ul {
    max-width: 300px !important;
  }
  font-size: 14px;
  letter-spacing: 0px;
  color: #282C37;
  font-family: ${MediumFont};
  color: #005c87;
  .caret {
    /* margin-right: 8px; */
    float: right;
    /* margin-top: 9px; */
    display: flex;
    align-self: center;
    color: #005c87
  }
  > li > a {
    text-overflow: ellipses;
    white-space: normal;
    overflow: hidden;
  }
  > span {
    color: #a3a3a3;
    float: right;
    border-top: 6px dashed;
    margin-top: 7px;
  }
  &:active, &:hover, &:focus, &:active:focus {
    border: 1px solid #cdcbcb;
    background-color: #faf9f4;
    color: #005c87;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  >div:last-child{
    margin-left: 15px;
  }
`;

const RequestContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  background: ${({background}) => background ? background : 'transparent'};
  margin-bottom: 15px;
  margin-top: 15px;
`;

const RequestText = styled.div`
  width: 100%;

  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color:#005C87;
  padding: 15px 0px 15px 15px;
  border-bottom: 1px solid rgba(156, 156, 156, 0.4);
`;

const RequestCardWrapper = styled.div`
  width: 100%;
  padding: 25px 15px 10px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: white;
`;

const RequestCard = styled.div`
  width: calc((100% - 15px) / 2);
  border-radius: 6px;
  border:1px solid rgba(156, 156, 156, 0.4);
  display: flex;
  margin-bottom 15px;
  .profile{
    width: 60px;
    height: 60px;
    margin: 10px 15px 10px 10px;
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
  .info{
    display: block;
    margin: auto 0px;
    .name{
      font-family: Rubik-Medium;
      font-size: 16px;
      line-height: 20px;
      color: #005C87;
      margin-bottom 5px;
      display: block;
    }
    .wellness-icon{
      display: flex;
      >div{
        width: 32px;
        height: 32px;
        margin-right: 5px;
        border-radius: 6px;
        display: flex;
        background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
        >img{
          margin: auto;
          width: 20px;
          height: 20px;
        }

        .hover-card{
          width: 0px;
          height: 0px;
          position: relative;
          display: block;
          >div{
            min-width: 65px;
            max-width: 120px;
            min-height: 25px;
            position: relative;
            right: 50px;
            bottom: 27px;
            background: rgba(156, 156, 156, 0.79);
    
            font-family: rubik;
            font-size: 12px;
            line-height: 12px;
            color: white;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            border-radius: 6px;
            padding: 5px 7px;
          }
        }

      }
    }
  }
  .last-card{
    display: flex;
    margin: auto 15px auto auto;
    .confirm{
      width: 110px;
      height: 40px;
      background: ${({background}) => background ? background : '#FD7175'};
      display: flex;
      margin: auto;
      align-items: center;
      margin-right: 8px;

      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      color:#FFFFFF;
      justify-content: center;
      border-radius: 6px;
      cursor:pointer;
    }
    .cancel{
      width: 110px;
      height: 40px;
      border: 1px solid rgba(58, 58, 58, 0.5);
      background: transparent;
      cursor: pointer;
      display: flex;
      margin: auto;
      align-items: center;

      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      color:#005C87;
      justify-content: center;
      border-radius: 6px;
      cursor:pointer;
    }
  }
`;

const MainSectionV2 = styled.div`
  width: 100%;
  float: left;
  height: 100%;
  margin-top: 25px;
  .pagination {
    margin-top: 40px;
    width: 100%;
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 500px) {
    padding: 0 15px 60px;
  }
`;

const HeaderWrapperV2 = styled.div`
  width: 100%;
  background: white;
  display: ${({display}) => display ? "flex" : 'block'};
  margin-bottom: 15px;
  border-radius:  6px;
  height: ${({height}) => height ? "70px" : ''};

  .first{
    display: flex;
    margin:auto;
    margin-left:0px;
    .teamIcon{
      >img{
      width: 30px;
      height: 22px;
      margin: auto 10px auto 15px;
    }
    }
    .teamName{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      color:#005C87;
    }  
  }
  .last{
    display: flex;
    margin:10px 15px 10px 0px;
  }
`;

const TopCard = styled.div`
  width: 100%;
  display: flex;
  min-height:45px;
  border-bottom: 1px solid rgba(156, 156, 156, 0.4);
  > div:nth-child(1) {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    .profile{
      width: 30px;
      height: 22px;
      margin: auto 10px auto 15px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .count{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      color:#005C87;
      align-items: center;
      display: flex;
    }
  }
  > div:nth-child(2) {
    width: 56%;
    display: flex;
    justify-content: flex-end;
  }
`;

const ViewContainerV2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
`;

const IconsContainerV2 = styled.div`
    display: flex;
    border-radius: 3px;
    width: 80px;
    align-items: center;
    margin: 15px 15px 15px 0px;
    div{
      > img {
    cursor: pointer;
    width: 40px;
  }

    }
  > img {
    cursor: pointer;
    width: 40px;
  }
  .listIcon,
  .gridIcon {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 1px solid #007AB1;
  }
  
  .listIcon {
    border-radius: 4px 0px 0px 4px;
  }
  
  .gridIcon {
    border-radius: 0px 4px 4px 0px;
  }
  
  .active {
    background-color: #007AB1;
  }
  `;

const FilterIcon = styled.div`
  display: flex;
  width: 100%;
`;  

const Menu = styled.div`
cursor: pointer;
width: 40px;
height: 40px;
background: ${({background}) => background ? background : 'transparent'};
border: ${({border}) => border ? '1px solid #D8D8D8' : 'none'};
display: flex;
justify-content: center;
align-items: center;
border-radius: ${({radius}) => radius ? '6px 0px 0px 6px' : '0px 6px 6px 0px'};
>img{
  width: 27px;
  width: ${({width}) => width ? "27px" : '22px'};
  height: ${({width}) => width ? "20px" : '22px'};
}
}
`; 

const StyledInputV2 = styled.input`
.placeholder{
  color: red;
}
placeholder{
  color: red;

}
  padding: 0px 15px 0px 15px;
    font-family: 'Rubik';
    font-weight: 'normal';
    font-size: 16px;
    line-height: 20px;
    width: 352px;
    width: 100%;
    height: 50px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 3px;
  color: #005C87;
  border: 0px;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: 'Rubik';
    color: rgba(0, 92, 135, 0.30);
  }
  :-ms-input-placeholder {
     color: red;
  }

  button, input, optgroup, select, textarea{
    color: #005C87;
  }
  
  &:hover, &:active, &:focus {
    outline: none;
  }
`;

const SearchFilter = styled.div`
width: 382px;
width: 30.7%;
max-width: 382px;
display: flex;
border: 1px solid rgba(156, 156, 156, 1);
margin: 15px;
border-radius: 3px;
`;




const SearchIcon = styled.div`
width: 20px;
height:20px;
margin: auto 15px 18px 15px;

`;

const CustomDropDownV2 = styled(DropdownButton)`
  width: 100%;
  display: ${({inputdisplay}) => inputdisplay ? inputdisplay : 'inline-block'};
  color: ${({newWidth}) => newWidth ? 'white' : 'white'};
  background-color: ${({newWidth}) => newWidth ? '#005C87' : '#ffffff'};
  align-items: center;
  padding: 0px 10px;
  border: 1px solid #cdcbcb;
  text-align: left;
  border: none;
  padding: ${({ inputpadding, newWidth }) =>
    inputpadding ? inputpadding : newWidth ? "9px 16px" : "9px 29px"};
  border-radius: 3px;
  color: #9c9c9c;
  max-width: 290px;
  margin: 0px;  
  button{
    display:flex;
  }
  .btn:hover: {
    background-color: none !important;
  }
  &:active,
  &:hover,
  &:focus,
  &:active:focus{
    color: #0d4270;
  }
  &:active {
    color: #333;
    background-color: #e6e6e6 !important;
    border-color: #adadad;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
  .dropdown {
    width: 100%;
  }
  .dropdown-menu {
    transform: translate3d(0px, 50px, 0px) !important;
  }  
  button {
    width: 100%;
    background: none !important;
    border: none;
    &::after {
      float: right;
      display: flex;
      align-self: center;
      color: rgb(13, 66, 112);
      border-width: 10px px;
      margin: auto 0px auto auto;
      font-size: 35px;
      margin-top: 4px;
      ${'' /* margin-left: ; */}
    }
    &:focus, &:hover{
      background-color: none !important;
    }
  }

  .dropdown-menu {
    top: 75%;
  }

  .title {
    text-overflow: ellipsis;
    font-family: "Rubik";
    float: left;
    max-width: 118px;
    white-space: nowrap;
    font-size: 16px;
    line-height: 20px;
    color: #005C87;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  > ul {
    max-width: 300px !important;
    top: 75%;
    height: 200px;
    overflow-y: auto;
  }
  font-size: 14px;
  letter-spacing: 0px;
  color: #282c37;
  font-family: ${MediumFont};
  color: #005C87;
  .caret {
    float: right;
    display: flex;
    align-self: center;
    color: #005C87;
    border-width:10px;
    margin: 0px;
    margin-top: 4px;
  }
  > li > a {
    text-overflow: ellipses;
    white-space: normal;
    overflow: hidden;
  }
  > span {
    color: #a3a3a3;
    float: right;
    border-top: 6px dashed;
    margin-top: 7px;
  }

  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    border:none;
    ${'' /* background-color: transparent; */}
    color: #005C87;
  }
`;




const DropdownTitle = styled.div`

  display: flex;
  justify-content:space-between;
  align-items:center;
  width:inherit;

  .arrow-icon {
    margin-top:0px;
  }

  .title {
    text-overflow: ellipsis;
    font-family: "Rubik";
    float: left;
    max-width: 120px;
    white-space: nowrap;
    font-size: 16px;
    line-height: 20px;
    color: #005C87;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const SearchFilterWrapper = styled.div`
  display: flex;
  margin: auto;
  margin-right: 0px;
  > div {
    min-width: 200px;
    max-width: 230px;
    margin: auto;
    height: 50px;
    border: 1px solid rgba(0, 122, 177, 0.3);
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 15px;
    .btn-group {
      width: 100%;
    }
    .dropdown-menu {
    }
    @media (max-width: 600px) {
      display: block;
      min-width: 170px;
      width: unset;
    }

  }

 
  .wellness-interest {
    width: 90%;
    max-width: 220px;
    margin: auto;
    display: flex;
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 15px;
    .dropdown {
      width: 100%;
    }
    &:active {
      color: #333 !important;
      background-color: #e6e6e6 !important;
      border-color: #adadad !important;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
    .dropdown-menu {
      transform: translate3d(0px, 50px, 0px);
    }
    button {
      width: 100%;
      background: none;
      border: none;
      &:focus,
      &:hover {
        background: none;
      }

      &::after {
        float: right;
        display: flex;
        align-self: center;
        color: rgb(13, 66, 112);
        border-width: 10px px;
        margin: auto 0px auto auto;
        font-size: 35px;
        margin-top: 4px;
        margin-left: ;
      }
    }
    div > button{
      padding: 14px 20px;
      border-radius: 0px;
    }
    div > button.show{
      color: #333 !important;
      background-color: #e6e6e6 !important;
      border-color: #adadad !important;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
    .btn-group {
      width: 100%;
    }
    .dropdown-menu {
      width: 100%;
      height: 400px;
      overflow-y: auto;
    }
    @media (max-width: 600px) {
      display: block;
      min-width: 170px;
      width: unset;
    }
    > div {
      width: 202px;
      display: flex;
      box-sizing: border-box;
      border-radius: 3px;
      .btn-group {
        width: 100%;
      }
      .dropdown-menu {
        width: 100%;
        height: 400px;
        overflow-y: auto;
        ::-webkit-scrollbar {
          width: 2px;
        }
      }
      @media (max-width: 600px) {
        display: block;
        min-width: 170px;
        width: unset;
      }
    }

    div > div.dropdown-menu.show {
      position: absolute !important;
      inset: 8px auto auto -14px !important;
      transform: translate(14.4444px, 42.2222px) !important;
      font-size: 14px;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
    }

    .dropdown-item:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
  .community-department {
    width: 100%;
    max-width: 220px;
    margin: auto;
    display: flex;
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 15px;
    .dropdown {
      width: 100%;
    }
    &:active {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    .dropdown-menu {
      transform: translate3d(0px, 50px, 0px) !important;
    }
    button {
      width: 100%;
      background: none;
      border: none;
      &:focus,
      &:hover {
        background: none;
      }

      &::after {
        float: right;
        display: flex;
        align-self: center;
        color: rgb(13, 66, 112);
        border-width: 10px px;
        margin: auto 0px auto auto;
        font-size: 35px;
        margin-top: 4px;
        margin-left: ;
      }
    }
    div > button{
      padding: 14px 20px;
      border-radius: 0px;
    }
    div > button.show{
      color: #333 !important;
      background-color: #e6e6e6 !important;
      border-color: #adadad !important;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
    .btn-group {
      width: 100%;
    }
    .dropdown-menu {
      height: ${({ scroll }) => (scroll ? "400px" : "")};
      overflow-y: ${({ scroll }) => (scroll ? "auto" : "none")};
    }
    @media (max-width: 600px) {
      display: block;
      min-width: 170px;
      width: unset;
    }
    > div {
      width: 202px;
      display: flex;
      box-sizing: border-box;
      border-radius: 3px;
      .btn-group {
        width: 100%;
      }
      .dropdown-menu {
        height: ${({ scroll }) => (scroll ? "400px" : "")};
        overflow-y: ${({ scroll }) => (scroll ? "auto" : "none")};
        ::-webkit-scrollbar {
          width: 2px;
        }
      }
      @media (max-width: 600px) {
        display: block;
        min-width: 170px;
        width: unset;
      }
    }

    div > div.dropdown-menu.show {
      font-size: 14px;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
    }

    .dropdown-item:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }

  }
  .location-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;

    div > button{
      padding: 14px 20px;
      border-radius: 0px;
    }
    div > button.show{
      color: #333 !important;
      background-color: #e6e6e6 !important;
      border-color: #adadad !important;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }

    div > div.dropdown-menu.show {
      font-size: 14px;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
      inset: 0px auto auto 0px !important;
    }

    .dropdown-item:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
`;

const BuddyTabContainer = styled.div`
display: flex;
gap: ${({gap}) => gap ? gap : '10px'}; // 10px;
width: ${({width}) => width ? width : '1248px'};
height: ${({height}) => height ? height : '56px'};
margin: auto;
background: ${({bgUnset}) => bgUnset ? bgUnset : 'white'}; // white;
border-radius: 6px;
margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '0px'};
padding-left: ${({pLeft}) => pLeft ? pLeft : '10px'}; // 10px;
align-items: center;
.text{
  color:#005C87;
  text-align: center;
  font-family: Rubik-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.year-dropdown{
  display: flex;
  margin: auto;
  margin-right: ${({marginRight}) => marginRight ? marginRight : '0px'};
 }
.radio-btn{
  width: 24px;
  height: 24px;
  left: 1516px;
  top: 278px;
  border: 1px solid #005C87;
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content:center;
  margin-top: 0px // 20px;
  cursor: pointer;

}
.innerCircle{
  display:${({show})=>show?"block":"none"};
  width: 16px;
  height: 16px;
  background: #FD7175;
  border-radius: 15px;
  cursor: pointer;
}
.bulk-bttn{
  font-family: 'Rubik-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #005C87;
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-left: 9px;
}
}
.backButton{
  display:flex;
  >div{
    align-items:center;
  }
}
`;



const YearDropDown= styled.div`
display:flex;
margin-left:auto;
${'' /* margin-right:5px; */}
align-items:center;
`;

const BuddyTab = styled.div`
display: flex;
min-width: ${({width}) => width ? "auto" : '114px'};
height: 40px;
padding: ${({padding}) => padding ? "10px 15px" : ''};
background: ${({active}) => active ? '#007AB1' : 'white'};
border-radius: 6px;
font-family: ${({active}) => active ? 'Rubik-Medium' : 'Rubik'};
font-size: 16px;
font-weight: 500;
line-height: 20px;
color: ${({active}) => active ? 'white' : '#007AB1'};
margin: ${({margin}) => margin ? margin : '8px 12px'};
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
.flex-item:not(:nth-child(1n)) {
  margin-left: 0;
}
&:nth-child(1n){
    margin-left: 0;
  }
justify-content: center;
align-items: center;
cursor: pointer;

:hover{
  background:#005C87;
  color:white;
  ${'' /* font-family:Rubik-Medium; */}
}
}
`;

const Heading = styled.div`
display: flex;
width: 100%;
font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color: #005C87;
margin-top :25px;
}
`;

const AddEmployeeButton = styled.div`
display: flex;
width: 184px;
height: 40px;
Cursor: pointer;
background: rgba(159, 201, 137, 1);
border-radius: 3px;

font-family: Rubik-Medium;
font-size: 16px;
line-height: 18px;
color:#FFFFFF;
justify-content: center;
align-items: center;
margin: 15px 15px 15px 0px;
}
`;

const ManageButton = styled.div`
display: flex;
width: ${({width}) => width ? width : '100px' };
height: ${({height}) => height ? height : '40px' };
background: ${({background}) => background ? background : '#FD7175' };
border-radius: 6px;
font-family: Rubik-Medium;
font-size: 16px;
line-height: 19px;
color: white;
margin: auto;
margin-right: 15px;
justify-content: center;
align-items: center;
cursor: pointer;
}
`;

const StyledTabContainer = styled(Tabs)`
   float: left;
  > ul {
    height: ${({newPadding}) => newPadding ? '70px' : 'none' };
    border-bottom: none;
    padding: ${({newPadding}) => newPadding ? '16px 10px 12px 18px' : '20px 20px 1px 33px' };
    font-size: 12px;
    text-transform: capitalize;
    color: rgb(153,153,153);
    background-color: #fff;
    
    > li {
      > a {
        font-size: 12px;
        letter-spacing: 0px;
        color: rgb(153,153,153);
        font-family: ${BoldFont};
        margin-right: 5vw;
        text-transform: capitalize;
        padding: 5px 5px 17px 5px;
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
    
    .active {
      border-bottom: none;
      > a {
        background-color: transparent;
        color: rgb(21,159,202);
        border: none;
        border-bottom: 3px solid rgb(21,159,202);
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border: none;
          border-bottom: 3px solid rgb(21,159,202);
          color: rgb(21,159,202);
        }
      }
    }
  }
`;

const StyledTabs = styled(StyledTabContainer)`
  width: 100%;
  margin: 0px;
  .tab-content {
    position: relative;
  }
  
  .company-container {
    float: right;
    margin-top: -53px;
    margin-right: 43px;
    
    @media (max-width: 639px) {
      float: left;
      margin-left: 63px;
    }
    @media (max-width: 800px) {
      float: right;
      
    }
    @media (max-width: 500px) {
      display: inline-block;
      float: none;
      margin: -27px 41px 0 33px;
      padding-top: 10px;
    }
    @media (max-width: 600px) and (min-width: 501px) {
        display: inline-block;
        float: right;
        margin: 0 41px 0 33px;
        padding-top: 10px;
    }
  }
  
  > ul {
    margin: 0;
    border-radius: 6px;
    padding: 15px 15px 15px 15px;
    margin-bottom: 25px;
    @media (max-width: 600px) {
      min-height: 100px;
      margin-top: 30px;
      padding: 4px 0px;
    }
    > li {
      width: 131px;
      display: flex;
      justify-content: center;
      height: 40px;
      margin-right: 20px;
      background:rgba(0, 47, 71, 1, .1);
      border-radius: 6px;
      @media (max-width: 600px) {
        text-align: center;
        float: none;
        width: 107px;
        margin: 0 auto;
      }
      > a {
        font-size: 15px;
        color: #CCCBDO;
        text-transform: none;
        font-family: ${BoldFont};
        margin-right: 0px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        padding: 10px 0px;

        font-family: 'Rubik-Regular';
        font-size: 16px;
        line-height: 20px;
        color:#005C87;
       }
      }
    }

    .active {
      > a {
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
        }
      }
    }
  }

  li.nav-item {
      @media (max-width: 600px) {
        float: none;
        width: 135px;
        margin: 0 auto;
      }
      >button {
				margin-right: 1vw;
        font-size: 16px;
        color: rgb(102, 157, 183);
        text-transform: none;
        font-family: ${MediumFont};
        width:131px;
        
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
       }
      }
    }

  li.nav-item {
    button.first-tab.nav-link.active  {
    background: rgb(242, 247, 249);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgb(0, 92, 135);
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    ${'' /* margin-top: -5px; */}
    }
  }
  li.nav-item {
    button.second-tab.nav-link.active  {
    background: rgb(242, 247, 249);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgb(0, 92, 135);
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    margin-top: -5px;
    }
  }
  li.nav-item {
    button.third-tab.nav-link.active  {
    background: rgb(242, 247, 249);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgb(0, 92, 135);
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    margin-top: -5px;
    }
  }

  li.first-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#005C87;
    }
  }
  li.second-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#005C87;
    }
  }
  li.third-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#005C87;
    }
  }
  li.first-tab.active {
      background: rgba(0, 47, 71, 0.1);
  }
  li.second-tab.active {
      background: rgba(0,47,71,0.1);
  }
  li.third-tab.active {
      background: rgba(0,47,71,0.1);
  }
  > ul > li > a{
    padding: 0px;

    font-family: 'Rubik-Regular';
    font-size: 18px;
    line-height: 21px;
    color:#005C87;
  }
`;

const CustomDropDownWellness = styled(DropdownButton)`
  width: 100%;
  display: ${({inputdisplay}) => inputdisplay ? inputdisplay : 'inline-block'};
  color: ${({newWidth}) => newWidth ? 'white' : 'white'};
  background-color: ${({newWidth}) => newWidth ? '#005C87' : '#ffffff'};
  align-items: center;
  padding: 0px 10px;
  border: 1px solid #cdcbcb;
  text-align: left;
  border: none;
  padding: ${({inputpadding,newWidth}) => inputpadding ? inputpadding : newWidth ? '9px 16px' : '9px 29px'};
  border-radius: 3px;
  color: #005C87;
  margin: 0px;
  display: flex;
  &:active, &:hover, &:focus, &:active:focus {
    color: #005C87;
  }
  button{
    display: flex;
    color:#005C87;
    background:white;
    border:none;
    :hover{
      color:#005C87;
      ${'' /* background:red; */}
    }
    :focus{
    ${'' /* background-color: red !important; */}
  }
  :focus-visible{
    ${'' /* background-color: red !important; */}
  }
  :active{
    ${'' /* background-color: red !important; */}
  }
  }



.dropdown-menu{
  top: 75%;
}

  .title {
    text-overflow: ellipsis;
    font-family: 'Rubik';
    float: left;
    white-space:nowrap;
    font-size: 16px;
    line-height: 20px;
    color: #005C87;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  > ul {
    max-width: 300px !important;
    top: 75%;
    height: 200px;
    overflow-y: auto;
  }
  font-size: 14px;
  letter-spacing: 0px;
  color: #282C37;
  font-family: ${MediumFont};
  color: #005C87;
  .caret {
    float: right;
    display: flex;
    align-self: center;
    color: #005C87;
    border-width:10px;
    margin: 0px;
    margin: auto;
    margin-right: 0px;
  }
  > li > a {
    text-overflow: ellipses;
    white-space: normal;
    overflow: hidden;
  }
  > span {
    color: #a3a3a3;
    float: right;
    border-top: 6px dashed;
    margin-top: 7px;
  }

  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    ${'' /* background-color: transparent; */}
    ${'' /* border:none; */}
    color: #005C87;
  }
`;

const PageWrapper = styled.div`
display: block;
width:100%;
`;

const TeamButton = styled.div`
height: 50px;
width: ${({width}) => width ? width : '385px'};
border-radius: 3px;
background: ${({background}) => background ? background : '#9FC989'};
cursor:pointer;
display:flex;
margin: ${({margin}) => margin ? margin : ''};

.icon{
  >img{
  width:40px;
  height:40px;
  margin:5px;
  display:flex;
  }
}
.buttonName{
  display:flex;
  font-family: "Rubik-Medium";
  font-size: 18px;
  line-height: 24px;
  alignItems:center;
  color:white;
  margin:auto;
  padding: 12px 20px;

  ${'' /* padding-right: 15%; */}
}


`;

const UpdateLocationButton = styled.button`
display: flex;
width: 184px;
height: 40px;
Cursor: pointer;
background: ${({background}) => background ? background : '#FD7175'};
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
border-radius: 3px;
border: none;
font-family: Rubik-Medium;
font-size: 16px;
line-height: 18px;
color:#FFFFFF;
justify-content: center;
align-items: center;
margin: 15px 15px 15px 0px;
}
`;





//styles for updated Peoplepage

const MainSectionView2 = styled.div`
  width: 100%;
  float: left;
  height: 100%;
  margin-top: 25px;
  .pagination {
    margin-top: 20px;
    width: 100%;
    display: flex;
    float: left;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .paginationInactivePage {
    margin-top: 40px;
    width: 100%;
    display: flex;
    float: left;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media (max-width: 500px) {
    padding: 0 15px 60px;
  }
`;


const BottomCardWrapper = styled.div`
   width: 100%;
    margin-top:20px;
`; 


const BottomCard = styled.div`
  width: 100%;
  display: flex;
  min-height:45px;
  > div:nth-child(1) {
    ${'' /* width: 16%;  */}
    width: ${({ widthChange ,teamsWidth }) => (widthChange ? "18%" : teamsWidth ? "15%" : "20%")};
    display: flex;
    justify-content: flex-start;
    .profile{
      width: 30px;
      height: 22px;
      margin: 7px 10px auto 0;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .count{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      color:#005C87;
      align-items: center;
      display: flex;
      margin: 0;
      
    }
  }
 
`;

const DividerLine = styled.div`
    width: ${({ teamsWidth }) => (teamsWidth ? "85%" : "80%")};
   ${'' /* width: 85%; */}
    ${'' /* display: flex; */}
    ${'' /* justify-content: flex-end; */}
    border-top: 1px solid var(--Navy_blue, #005c87);
    margin:auto 0 auto auto;
    ${'' /* margin-right:10px; */}
    opacity: 30%;
`; 



const BuddyTab1 = styled.div`
display: flex;
min-width: ${({width}) => width ? "auto" : '114px'};
height: 40px;
padding: ${({padding}) => padding ? "10px 15px" : ''};
background: ${({active}) => active ? '#007AB1' : 'white'};
border-radius: 6px;
font-family: ${({active}) => active ? 'Rubik-Medium' : 'Rubik'};
font-size: 16px;
font-weight: 500;
line-height: 20px;
color: ${({active}) => active ? '#FFFFFF' : '#007AB1'};
margin: ${({margin}) => margin ? margin : '10px 15px 10px 15px'};
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
.flex-item:not(:nth-child(1n)) {
  margin-left: 0;
}
&:nth-child(1n){
    margin-left: 0;
  }
justify-content: center;
align-items: center;
cursor: pointer;

:hover{
  background:#005C87;
  color:#ffff;
  ${'' /* font-family:Rubik-Medium; */}
}

`;


const FilterIcon1 = styled.div`
  display: flex;
  width: 100%;
  justify-content:space-between ;
  align-items:center;
  margin-top:15px;
  margin-bottom:10px;

  ${'' /* .wrapper{
    width:40%;
  } */}
`;  


const SearchFilter1 = styled.div`
width: 280px;
width: 30.7%;
max-width: 280px;
display: flex;
border: 1px solid rgba(0, 122, 177, 0.3);
margin: 15px 15px 15px 0;
border-radius: 4px;
background: #FFFFFF;
 
  
&:active, &:hover, &:focus, &:active:focus {
  border: 1px solid rgba(0, 122, 177, 0.3);
  }

  .search-icon{
    display:flex;
    align-items:center;
    margin:0px 10px 0px 0px;
    
  }


`;



const SearchFilterContainer1 = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  margin-right: 0px;
  justify-content: ${({ hasDepartmentData, hasLocationData }) => 
    hasDepartmentData && hasLocationData ? 'space-between' : 'flex-end'};
  
  > div {
    min-width: 50px;
    max-width: 80px;
    margin: auto;
    height: 50px;
    border: 1px solid rgba(0, 122, 177, 0.3);
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 15px;

    @media (max-width: 600px) {
      display: block;
      min-width: 30px;
      width: unset;
    }
  }

  .filterTitle {
    width: 55px;
    height: 20px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color:#005C87;
    opacity:30%;
    border:none;
  }

  .community-department {
    width: 100%;
    min-width: 180px;
    max-width: 180px;
    margin: auto;
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: ${({ inactiveUser }) => inactiveUser ? '0' : '15px'};
    
    &:active, &:hover, &:focus, &:active:focus {
    border:none;
  }

    .dropdown {
      width: 100%;
    }
    &:active {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    .dropdown-menu {
      transform: translate3d(0px, 50px, 0px) !important;
    }
    button {
      width: 100%;
      background: none;
      border: none;
      &:focus,
      &:hover {
        background: none;
      }

      &::after {
        float: right;
        display: none;
        align-self: center;
        color: rgb(13, 66, 112);
        border-width: 10px px;
        margin: auto 0px auto auto;
        font-size: 35px;
        margin-top: 4px;
      }
    }
    div > button{
      padding: 4px 12px;
      border-radius: 0px;
    }
    div > button.show{
      color: #333 !important;
      border-color: #adadad !important;
    }
    .btn-group {
      width: 100%;
    }
    .dropdown-menu {
      height: ${({ scroll }) => (scroll ? "400px" : "")};
      overflow-y: ${({ scroll }) => (scroll ? "auto" : "none")};
    }
    @media (max-width: 600px) {
      display: block;
      min-width: 170px;
      width: unset;
    }
    > div {
      width: 202px;
      display: flex;
      box-sizing: border-box;
      border-radius: 3px;
      .btn-group {
        width: 100%;
      }
      .dropdown-menu {
        height: ${({ scroll }) => (scroll ? "400px" : "")};
        overflow-y: ${({ scroll }) => (scroll ? "auto" : "none")};
        ::-webkit-scrollbar {
          width: 2px;
        }
      }
      @media (max-width: 600px) {
        display: block;
        min-width: 170px;
        width: unset;
      }
    }

    div > div.dropdown-menu.show {
      font-size: 14px;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
    }

    .dropdown-item:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }

  .location-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;

    div > button {
      padding: 14px 20px;
      border-radius: 0px;
    }
    div > button.show {
      color: #333 !important;
      background-color: #e6e6e6 !important;
      border-color: #adadad !important;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }

    div > div.dropdown-menu.show {
      font-size: 14px;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
      inset: 0px auto auto 0px !important;
    }

    .dropdown-item:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
`;








const SearchFilterContainer = styled.div`
  display: flex;
  ${'' /* width: 100%; */}
  margin: auto;
  justify-content: ${({ hasDepartmentData, hasLocationData }) => 
    hasDepartmentData && hasLocationData ? 'space-between' : 'flex-end'};
  margin-right: 0px;
  > div {
    min-width: 50px;
    max-width: 80px;
    margin: auto;
    height: 50px;
    border: 1px solid rgba(0, 122, 177, 0.3);
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 15px;
    .btn-group {
      width: 50%;
    }
    .dropdown-menu {
    }
    @media (max-width: 600px) {
      display: block;
      min-width: 30px;
      width: unset;
    }
  }
  .filterTitle{
  width: 55px;
  height: 20px;
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color:#005C87;
  opacity:30%;
  border:none;
  }

  .wellness-interest {
    width: 100%;
    min-width: 180px;
    max-width: 180px;
    margin: auto;
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;

    &:active, &:hover, &:focus, &:active:focus {
    border:none;
  }

    ${'' /* margin-right: 15px; */}
    .dropdown {
      width: 100%;
    }
    &:active {
      color: #333 !important;
      background-color: #e6e6e6 !important;
      border-color: #adadad !important;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
    .dropdown-menu {
      transform: translate3d(0px, 50px, 0px);
    }
    button {
      width: 100%;
      background: none;
      border: none;
      &:focus,
      &:hover {
        background: none;
      }

      &::after {
        float: right;
        display: none;
        align-self: center;
        color: rgb(13, 66, 112);
        border-width: 10px px;
        margin: auto 0px auto auto;
        font-size: 35px;
        margin-top: 4px;
        margin-left: ;
      }
    }
    div > button{
      padding: 4px 12px;
      border-radius: 0px;
    }
    div > button.show{
      color: #333 !important;
      ${'' /* background-color: #e6e6e6 !important; */}
      border-color: #adadad !important;
      ${'' /* -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; */}
      ${'' /* box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; */}
    }
    .btn-group {
      width: 100%;
    }
    .dropdown-menu {
      width: 100%;
      height: 400px;
      overflow-y: auto;
    }
    @media (max-width: 600px) {
      display: block;
      min-width: 170px;
      width: unset;
    }
    > div {
      width: 202px;
      display: flex;
      box-sizing: border-box;
      border-radius: 3px;
      .btn-group {
        width: 100%;
      }
      .dropdown-menu {
        width: 100%;
        height: 400px;
        overflow-y: auto;
        ::-webkit-scrollbar {
          width: 2px;
        }
      }
      @media (max-width: 600px) {
        display: block;
        min-width: 170px;
        width: unset;
      }
    }

    div > div.dropdown-menu.show {
      position: absolute !important;
      inset: 8px auto auto -14px !important;
      transform: translate(14.4444px, 42.2222px) !important;
      font-size: 14px;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
    }

    .dropdown-item:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
  .community-department {
    width: 100%;
    min-width: 180px;
    max-width: 180px;
    margin: auto;
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: ${({ inactiveUser }) => inactiveUser ? '0' : '15px'};
    
    &:active, &:hover, &:focus, &:active:focus {
    border:none;
  }

    .dropdown {
      width: 100%;
    }
    &:active {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    .dropdown-menu {
      transform: translate3d(0px, 50px, 0px) !important;
    }
    button {
      width: 100%;
      background: none;
      border: none;
      &:focus,
      &:hover {
        background: none;
      }

      &::after {
        float: right;
        display: none;
        align-self: center;
        color: rgb(13, 66, 112);
        border-width: 10px px;
        margin: auto 0px auto auto;
        font-size: 35px;
        margin-top: 4px;
        margin-left: ;
      }
    }
    div > button{
      padding: 4px 12px;
      border-radius: 0px;
    }
    div > button.show{
      color: #333 !important;
      ${'' /* background-color: #e6e6e6 !important; */}
      border-color: #adadad !important;
      ${'' /* -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; */}
      ${'' /* box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; */}
    }
    .btn-group {
      width: 100%;
    }
    .dropdown-menu {
      height: ${({ scroll }) => (scroll ? "400px" : "")};
      overflow-y: ${({ scroll }) => (scroll ? "auto" : "none")};
    }
    @media (max-width: 600px) {
      display: block;
      min-width: 170px;
      width: unset;
    }
    > div {
      width: 202px;
      display: flex;
      box-sizing: border-box;
      border-radius: 3px;
      .btn-group {
        width: 100%;
      }
      .dropdown-menu {
        height: ${({ scroll }) => (scroll ? "400px" : "")};
        overflow-y: ${({ scroll }) => (scroll ? "auto" : "none")};
        ::-webkit-scrollbar {
          width: 2px;
        }
      }
      @media (max-width: 600px) {
        display: block;
        min-width: 170px;
        width: unset;
      }
    }

    div > div.dropdown-menu.show {
      font-size: 14px;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
    }

    .dropdown-item:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }

  }
  .location-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;

    div > button{
      padding: 14px 20px;
      border-radius: 0px;
    }
    div > button.show{
      color: #333 !important;
      background-color: #e6e6e6 !important;
      border-color: #adadad !important;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }

    div > div.dropdown-menu.show {
      font-size: 14px;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
      inset: 0px auto auto 0px !important;
    }

    .dropdown-item:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
`;


// const LineText = styled.span`
//   flex: 1;
//   border-top: 1px solid var(--Navy_blue, #005c87);
//   margin-top: 10px;
//   opacity: 30%;
// `;


export { Layout, PageTitle, HeaderWrapper, MainSection,YearDropDown, Button, AddLogo, Container, SideSection,CustomDropDownv3,
  IconsContainer, SortSection, StyledInput, ViewContainer, AddButtonContainer, CustomDropDown, HeaderTitlePeopleHomeV2, HeaderImagePeopleHomeV2,
  HeaderContainerPeopleHomeV2, ButtonNew,SearchFilterContainer1, ImageWrapper, RequestContainer, RequestText, RequestCardWrapper, RequestCard, MainSectionV2, HeaderWrapperV2, TopCard, ViewContainerV2, IconsContainerV2, FilterIcon, Menu, StyledInputV2, SearchFilter, SearchIcon, CustomDropDownV2, SearchFilterWrapper, BuddyTabContainer, BuddyTab, Heading, AddEmployeeButton, ManageButton, StyledTabs, StyledTabContainer, CustomDropDownWellness, PageWrapper, DropdownTitle,TeamButton, UpdateLocationButton, CustomDropDownItem, BottomCard, MainSectionView2, BuddyTab1, FilterIcon1, SearchFilter1, SearchFilterContainer, DividerLine,BottomCardWrapper};